import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

import Layout from "../components/Layout1"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Form from "../components/JoinForm"

const JoinPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: wordpressPage(slug: { eq: "join" }) {
        wordpress_id
        title
        slug
        content
      }
    }
  `)

  const page = data.page

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          {modal ? (
            <>
              <Link
                to={closeTo}
                className="absolute top-0 right-0 px-4 py-2 text-3xl leading-none hover:bg-gray-400"
              >
                &times;
              </Link>
              <Header className="w-full md:w-10/12 xl:w-8/12 px-4 mx-auto my-6 block" />
              <div className="w-full mx-auto mb-16">
                <h2 className="w-full bg-adobe py-4 mb-10 leading-none items-center font-sans font-bold text-white text-2xl lg:text-4xl text-center">
                  {page.title}
                </h2>
                <div
                  className="mt-2 px-10 font-serif font-light text-black text-xl p-with-leading"
                  dangerouslySetInnerHTML={{ __html: page.content }}
                ></div>

                <div className="px-10 font-serif font-light text-black text-xl">
                  <Form />
                </div>
              </div>
            </>
          ) : (
            <Layout>
              <link
                href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Teko:wght@300;400;500;600;700&display=swap"
                rel="stylesheet"
              ></link>
              <SEO title="Join" />
              <div className="w-full lg:w-8/12 mx-auto mb-16">
                <h2 className="w-full bg-adobe py-4 mb-10 leading-none items-center font-sans font-bold text-white text-2xl lg:text-4xl text-center">
                  {page.title}
                </h2>
                <div
                  className="mt-2 px-10 font-serif font-light text-black text-xl p-with-leading"
                  dangerouslySetInnerHTML={{ __html: page.content }}
                ></div>

                <div className="px-10 font-serif font-light text-black text-xl">
                  <Form />
                </div>
              </div>
            </Layout>
          )}
        </>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default JoinPage
