import React from "react"

const JoinForm = () => {
  return (
    <form
      className="w-full lg:grid lg:grid-cols-2 lg:col-gap-10 lg:row-gap-8 mb-12"
      name="farm-signup"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      //   data-netlify-recaptcha="true"
      method="POST"
      enctype="multipart/form-data"
      action="/thank-you"
    >
      <input type="hidden" name="form-name" value="farm-signup" action="/thank-you" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field"/>
        </label>
      </p>	  
      <h2 className="font-serif text-black font-bold text-2xl col-span-2">
        Your Information
      </h2>
      <div>
        <label htmlFor="firstname" className="label">
          First Name *
        </label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          aria-label="First Name"          
          placeholder=""
          required
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="firstname" className="label">
          Last Name *
        </label>
        <input
          type="text"
          id="lastname"
          name="lastname"
          aria-label="Last Name"          
          placeholder=""
          required
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="phone" className="label">
          Phone *
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          aria-label="Phone Number"          
          placeholder=""
          required
          inputmode="tel"
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="email" className="label">
          Email Address *
        </label>
        <input
          type="email"
          id="email"
          name="email"
          aria-label="Email"          
          placeholder=""
          required
          inputmode="email"
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="website" className="label">
          Website
        </label>
        <input
          type="text"
          id="website"
          name="website"
          aria-label="Website"          
          placeholder=""
          inputmode="url"
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="facebook" className="label">
          Facebook
        </label>
        <input
          type="text"
          id="facebook"
          name="facebook"
          aria-label="Facebook"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="instagram" className="label">
          Instagram
        </label>
        <input
          type="text"
          id="instagram"
          name="instagram"
          aria-label="Instagram"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <h2 className="mt-4 font-bold font-serif text-black text-2xl col-span-2">
        Farm Information
      </h2>
      <div className="col-span-2">
        <label htmlFor="farmname" className="label">
          Farm Name *
        </label>
        <input
          type="text"
          id="farmname"
          name="farmname"
          aria-label="Farm Name"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="address" className="label">
          Address *
        </label>
        <input
          type="text"
          id="address"
          name="address"
          aria-label="Address"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label htmlFor="city" className="label">
          City *
        </label>
        <input
          type="text"
          id="city"
          name="city"
          aria-label="City"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="col-span-2">
        <label htmlFor="datesopen" className="label">
          Dates Open *
        </label>
        <input
          type="text"
          id="datesopen"
          name="datesopen"
          aria-label="Dates Open"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="col-span-2">
        <label htmlFor="hours" className="label">
          Hours *
        </label>
        <input
          type="text"
          id="hours"
          name="hours"
          aria-label="Hours Open"
          placeholder=""
          className="field text-gray-700 focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="col-span-2">
        <label htmlFor="description" className="label">
          Please list the items that you sell <small>(75 word max)</small>
        </label>
        <textarea
          name="description"
          aria-label="List the items that you sell"
          rows="5"
          placeholder=""
          className=" field text-gray-700 focus:outline-none focus:shadow-outline"
        ></textarea>
      </div>
      <div className="col-span-2">
        <label htmlFor="image" className="label">
          Attach an image
        </label>
        <input
          type="file"
          name="image"
          id="image"
          className="file-input block w-full pb-10"
        ></input>
        {/* <div data-netlify-recaptcha="true"></div> */}
      </div>
      <button
        type="submit"
        aria-label="Submit"
        className="col-span-2 bg-red hover:bg-adobe text-white font-bold font-serif py-3 px-10 text-xl rounded place-self"
      >
        SIGN UP NOW
      </button>
    </form>
  )
}

export default JoinForm
